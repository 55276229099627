import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import ja from '@/locales/ja.json'
import { JAPANESE_CODE } from '@/consts/localization';

// use(VueI18n)をnew VueI18nの前に実行しないとエラーになる
Vue.use(VueI18n);

export function getCurrentLocale() {
  const config = JSON.parse(localStorage.getItem('awash_web') || '{}')
  return config.localization || JAPANESE_CODE
}

const i18n = new VueI18n({
  locale: getCurrentLocale(),
  messages:{
    en,
    ja
  }
})

function localizeLovWeeks(weekMasterData) {
  weekMasterData.forEach(week => {
    week.val = i18n.t(`master.dayOfWeek.${week.key}`)
  })
}

function localizeLovPlanParts(planPart) {
  planPart.forEach(part => {
    part.val = i18n.t(`master.planPart.${part.key}`)
  })
}

function localizeLovPaymentMethod(paymentMethod) {
  paymentMethod.forEach(method => {
    method.val = i18n.t(`master.paymentMethod.${method.key}`)
  })
}

function localizeLovPickupDeliveryType(pdType) {
  pdType.forEach(type => {
    type.val = i18n.t(`master.pickupDeliveryType.${type.key}`)
  })
}

function localizeLovOneTimePaymentMethod(onetimePaymentMethod) {
  onetimePaymentMethod.forEach(method => {
    method.val = i18n.t(`master.onetimePaymentMethod.${method.key}`)
  })
}

export function localizeLovs(lovs) {
  localizeLovWeeks(lovs.week)
  localizeLovPlanParts(lovs.plan_part)
  localizeLovPaymentMethod(lovs.payment_method)
  localizeLovOneTimePaymentMethod(lovs.onetime_payment_method)
  localizeLovPickupDeliveryType(lovs.pd_type)
}

export function localizePlanMaster(plans) {
  plans.forEach(plan => {
    plan.name = localizePlanName(plan.plan_id)
  })
}

function breakdownPlanId(planId) {
  // 形式 {プラン}_{受渡方法分類}(_1: 集配同時フラグ)(_{M|L|LL}: バッグサイズ)
  // 参照先：https://github.com/AWESOMEWASH/awash-web/blob/dev/main/src/app/Helpers/PlanIdParser.php#L16-L41
  const [planPartId, pickupDeliveryType, pickupDeliverySameTimeFlagOrBagSize, maybeBagSize] = planId.split('_')
  const pickupDeliverySameTimeFlag = pickupDeliverySameTimeFlagOrBagSize == '1' ? '1' : null
  const bagSize = maybeBagSize
    ?? (pickupDeliverySameTimeFlagOrBagSize && pickupDeliverySameTimeFlagOrBagSize != '1'
      ? pickupDeliverySameTimeFlagOrBagSize
      : "S")

  return {
    planPartId,
    pickupDeliveryType,
    pickupDeliverySameTimeFlag,
    bagSize,
  }
}

function localizePlanName(planId) {
  const planInfo = breakdownPlanId(planId)
  const planPart = i18n.t(`master.planPart.${planInfo.planPartId}`)
  const pickupDeliveryType = i18n.t(`master.plan.pickupDeliveryType.${planInfo.pickupDeliveryType}`)
  const pickupDeliverySameTime = planInfo.pickupDeliverySameTimeFlag === '1'
    ? i18n.t('master.plan.pickupDeliverySameTime')
    : ''
  const bagSize = planInfo.bagSize ? planInfo.bagSize + i18n.t('size') : ''
  return [planPart, pickupDeliveryType + pickupDeliverySameTime, bagSize].filter(e => !!e).join('/')
}

export function getLocalizedText(key) {
  return i18n.t(key)
}

export function shouldUseKana() {
  return getCurrentLocale() === JAPANESE_CODE
}

export default i18n
