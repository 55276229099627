<template>
  <div>
    <no-login-header />
    <h1 class="pagetitle">{{ $t('register.title') }}</h1>
    <InputStepView
      v-if="isInputStep"
      @to-confirm-step="toConfirmStep"
    />
    <ConfirmStepView
      v-else-if="isConfirmStep"
      @back-to-input-step="backToInputStep"
    />
    <no-login-footer />

    <SameTimeDeliveryNoteModal
      v-if="state.showSameTimeDeliveryNoteModal"
      @dismiss="state.showSameTimeDeliveryNoteModal = false"
    />

    <modal
      v-if="registerState.showPageUnavailableForUserModal"
      :title="$t('registerViaPartnerVenue.warning')"
      :showBottomDismissButton=false
      @close="redirectToMyPage()"
      @dismiss="registerState.showPageUnavailableForUserModal = false">
      <div slot="body">
        <div>
          <p class="font_orange font_medium">
            {{ $t('registerViaPartnerVenue.pageUnavailableForUser') }}<br><br>
            {{ $t('registerViaPartnerVenue.okToMyPage') }}
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import { defineComponent, onMounted, computed, reactive } from 'vue'
import areaInfoApi from '@/api/areaInfo'
import venueApi from '@/api/venue'
import { provideMaster } from '@/composables/useMaster'
import { provideUserState } from '@/components/lib/user/composables/useUserState'
import { provideUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { provideCommonState } from '@/components/Register/composables/useCommonState'
import InputStepView from '@/components/RegisterViaPartnerVenue/components/InputStepView.vue'
import ConfirmStepView from '@/components/Register/components/ConfirmStepView.vue'
import SameTimeDeliveryNoteModal from '@/components/lib/SameTimeDeliveryNoteModal.vue'
import i18n from '@/utils/i18n'
import { useRoute } from '@/hook/useRoute'
import { useStore } from '@/hook/useStore'

export default defineComponent({
  name: 'register-via-partner-venue',
  setup() {
    // TODO プランに関係するものはusePlanStateを作って切り出したい
    const state = provideCommonState()
    const userState = provideUserState()
    provideUserErrorState()
    const { route } = useRoute()
    const store = useStore()
    const registerState = reactive({
      showPageUnavailableForUserModal: false,
    })

    const { _masterPromise: masterPromise } = provideMaster()

    const isInputStep = computed(() => {
      return state.currentStep === 1
    })
    const isConfirmStep = computed(() => {
      return state.currentStep === 2
    })
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])

    function toConfirmStep() {
      state.currentStep = 2
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function backToInputStep() {
      state.currentStep = 1
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }

    onMounted(async () => {
      try {
        // URLから取得したvenueCodeを元にvenueを取得
        // venue_codeに該当するvenueが取得できなかった場合、通常の申し込み画面にリダイレクトする
        const venueCode = route.value.params.venueCode
        const { data: venue } = await venueApi.getVenue(venueCode)
        userState.venue = venue
        userState.source_venue_code = venueCode
      } catch (e) {
        window.location.href = '/register'
        return
      }
      const config = JSON.parse(localStorage.getItem('awash_web')) || {}
      if (!config.localization) {
        const defaultLang = userState.venue.default_lang
        await store.dispatch('localStorage/set', {
          key: 'localization',
          val: defaultLang,
        })
        i18n.locale = defaultLang
        window.location.reload()
      }

      // TODO commonStateからmasterData周りのものを剥がしていきたい
      const [masterData, areaInfoResponse] = await Promise.all([
        masterPromise,
        areaInfoApi.index(),
      ])

      state.lovs = masterData.lovs
      state.plans = masterData.plans
      state.planMap = masterData.planMap
      state.lovsMap = masterData.lovsMap
      state.other = masterData.other

      if (state.lovs.plan_part) {
        state.planParts1 = state.lovs.plan_part.filter(p => {
          // 単発のみ選択可能
          return p['key'] === 'tx'
        })
        state.planPart1 = state.planParts1[0].key
      }

      state.areaInfos = areaInfoResponse.data

      if (isLoggedIn.value) {
        const me = await store.dispatch('user/getMe')
        if (!me.source_venue_code) {
          registerState.showPageUnavailableForUserModal = true
          return
        }
        // ログインユーザーの情報から入力情報を全て埋めておく
        // 住所は読んだQRに紐づく住所に変更して表示するため埋めない
        await fillUserStateFromMe(me)
      }
    })

    const fillUserStateFromMe = async(me) => {
      userState.name1 = me.name1
      userState.name2 = me.name2
      userState.kana1 = me.kana1
      userState.kana2 = me.kana2
      userState.phone_number = me.phone_number
      userState.email = me.email
      userState.email_confirm = me.email
      userState.bikou1 = me.bikou1
    }

    const redirectToMyPage = () => {
      registerState.showPageUnavailableForUserModal = false
      location.href = '/'
    }

    return {
      state,
      registerState,
      // computed
      isInputStep,
      isConfirmStep,
      // methods
      toConfirmStep,
      backToInputStep,
      redirectToMyPage,
    }
  },
  components: {
    InputStepView,
    ConfirmStepView,
    SameTimeDeliveryNoteModal,
  },
})
</script>

<style lang="scss" scoped>
</style>
