<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr>
        <th><p class="item2">{{ $t('profile.managerName') }}</p></th>
        <td><p>
          <span>{{userState.name1}}</span>
          <span>{{userState.name2}}</span>
        </p></td>
      </tr>
      <template v-if="isKanaVisible">
        <tr>
          <th><p class="item2">{{ $t('profile.managerNameKana') }}</p></th>
          <td><p>
            <span>{{userState.kana1}}</span>
            <span>{{userState.kana2}}</span>
          </p></td>
        </tr>
      </template>
      <tr>
        <th><p class="item2">{{ $t('profile.companyName') }}</p></th>
        <td><p>{{userState.company_name}}</p></td>
      </tr>
      <template v-if="isKanaVisible">
        <tr>
          <th><p class="item2">{{ $t('profile.companyNameKana') }}</p></th>
          <td><p>{{userState.company_name_kana}}</p></td>
        </tr>
      </template>
      <tr>
        <th><p class="item2">{{ $t('profile.headquartersAddress') }}</p></th>
        <td>
          <p>〒{{zipcodeDisp(userState.bill_addr_zipcode)}}</p>
          <p>{{userState.bill_addr_detail1}}{{userState.bill_addr_detail2}}</p>
          <p>{{userState.bill_addr_detail3}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.headquartersPhone') }}</p></th>
        <td><p>{{userState.company_hq_phone_number}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.representativeName') }}</p></th>
        <td><p>
          <span>{{userState.company_ceo_name1}}</span>
          <span>{{userState.company_ceo_name2}}</span>
        </p></td>
      </tr>
      <template v-if="isKanaVisible">
        <tr>
          <th><p class="item2">{{ $t('profile.representativeNameKana') }}</p></th>
          <td><p>
            <span>{{userState.company_ceo_name_kana1}}</span>
            <span>{{userState.company_ceo_name_kana2}}</span>
          </p></td>
        </tr>
      </template>
      <tr>
        <th><p class="item2">{{ $t('profile.deliveryAddress') }}</p></th>
        <td>
          <p>〒{{zipcodeDisp(userState.send_addr_zipcode)}}</p>
          <p>{{userState.send_addr_detail1}}{{userState.send_addr_detail2}}</p>
          <p>{{userState.send_addr_detail3}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.storeName') }}</p></th>
        <td><p>{{userState.send_addr_name}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.phoneNumber') }}</p></th>
        <td><p>{{userState.phone_number}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.email') }} ①</p></th>
        <td><p>{{userState.email}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('profile.email') }} ②</p></th>
        <td><p>{{userState.email2}}</p></td>
      </tr>
      <tr v-if="showPaymentMethod">
        <th><p class="item2">{{ $t('paymentMethod') }}</p></th>
        <td><p>{{paymentMethodDisp}}{{ $t('profile.paymentSuffix') }}</p></td>
      </tr>
      <tr>
        <th class="form_line_l"><p class="item2">{{ $t('profile.remarks') }}</p></th>
        <td class="form_line_r"><p v-html="lineBreakToBR(userState.bikou1)"></p></td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue, { computed, defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useMaster } from '@/composables/useMaster'
import { shouldUseKana } from '@/utils/i18n'

export default defineComponent({
  props: {
    showPaymentMethod: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const master = useMaster()
    const { userState } = useUserState()
    const isKanaVisible = shouldUseKana()

    const paymentMethodDisp = computed(() => {
      const obj = (master.lovsMap.payment_method || {})[userState.payment_method_id]
      if (!obj) { return 0 }
      return obj.val
    })

    const zipcodeDisp = (zc) => {
      if (!zc || zc.length !== 7) { return zc }
      return zc.slice(0, 3) + '-' + zc.slice(3, 7)
    }

    return {
      userState,
      isKanaVisible,
      // computed
      paymentMethodDisp,
      // methods
      zipcodeDisp,
      lineBreakToBR: Vue.filter('lineBreakToBR'),
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_title {
  width: 99.7%;
  margin: 30px 0 0 0 !important;
  border: solid 1px #00A9CA !important;
  text-align: center;

  p {
    height: 60px;
    color: #00A9CA;
    font-weight: normal;
    line-height: 60px;
  }
}

@media screen and (max-width: 640px) {
  .wrap_title {
    width: 99%;
  }
}
</style>
