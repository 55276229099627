import { shouldUseKana } from '@/utils/i18n'

export function convertUserDataForSave(userState) {
  // kanaは必須項目なので、利用しない場合でもnameをそのままコピーする
  if (!shouldUseKana()) {
    userState.kana1 = userState.name1
    userState.kana2 = userState.name2
    userState.company_name_kana = userState.company_name
    userState.company_ceo_name_kana1 = userState.company_ceo_name1
    userState.company_ceo_name_kana2 = userState.company_ceo_name2
  }

  // 個人ユーザーの場合に、集配先住所名称を自動で付加し、集配先住所を請求先住所にコピーする.
  // 個人/法人フラグをつける前のデータではbill_address系に集配先住所が入っていたが、個人/法人フラグ
  // をつける際に一旦bill_address系をsend_address系にコピーして、以後はsend_address系を集配先住所として取り扱うようにした.
  // しかしコード内で集配先住所としてbill_addressを見ている箇所を先に全部つぶすのが困難と思われたため、
  // とりあえずsend_addressをbill_addressにコピーしておけば困ったことにはならないだろうということでこの処理を入れた.
  if (userState.is_company) { return }

  // パートナー会社経由の場合は、send_addr_detail3に部屋番号を付加する
  if (!!userState.source_venue_code) {
    const buildingName = userState.send_addr_detail3.trim().split('@')[0]
    userState.send_addr_detail3 = `${buildingName} @${userState.send_addr_detail3_room_number}`
  }

  userState.send_addr_name = '住所1' // 何か入れる
  userState.bill_addr_zipcode = userState.send_addr_zipcode
  userState.bill_addr_detail1 = userState.send_addr_detail1
  userState.bill_addr_detail2 = userState.send_addr_detail2
  userState.bill_addr_detail3 = userState.send_addr_detail3
}

export function getValidPaymentMethodKeys(isCompany) {
  // 法人 => クレカ、請求書
  // 個人 => クレカ
  return isCompany ? ['1', '2'] : ['1']
}

export function getValidOneTimePaymentMethodKeys(isCompany) {
  // 法人 => クレカ、Paypay, 請求書(Paid)
  // 個人 => クレカ, Paypay
  return isCompany ? ['1', '2', '3'] : ['1', '2']
}
