<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr v-if="showContractType">
        <th><p class="item3">{{ $t('profile.contractType') }}<span class="font_orange">*</span></p></th>
        <td>
          <div class="wrap_input">
            <div class="slc">
              <input type="radio" id="is-company-option1" :value="1" @change="onContractModeChange" />
              <label for="is-company-option1" class="ml5">{{ $t('profile.individual') }}</label>
            </div>
            <div class="slc">
              <input type="radio" id="is-company-option2" :value="2" checked />
              <label for="is-company-option2" class="ml5">{{ $t('profile.corporate') }}</label>
            </div>
          </div>
        </td>
      </tr>
      <NameInput
        :item-label="$t('profile.managerName')"
        item-name-prefix="name"
      />
      <NameInput
        v-if="isKanaVisible"
        :item-label="$t('profile.managerNameKana')"
        item-name-prefix="kana"
      />
      <GeneralRequiredInput
        :item-label="$t('profile.companyName')"
        item-name="company_name"
      />
      <GeneralRequiredInput
        v-if="isKanaVisible"
        :item-label="$t('profile.companyNameKana')"
        item-name="company_name_kana"
      />
      <AddressInput
        :item-label="$t('profile.headquartersAddress')"
        item-name-prefix="bill"
      />
      <PhoneNumberInput
        :item-label="$t('profile.headquartersPhone')"
        item-name="company_hq_phone_number"
      />
      <NameInput
        :item-label="$t('profile.representativeName')"
        item-name-prefix="company_ceo_name"
      />
      <NameInput
        v-if="isKanaVisible"
        :item-label="$t('profile.representativeNameKana')"
        item-name-prefix="company_ceo_name_kana"
      />
      <AddressInput
        :item-label="$t('profile.deliveryAddress')"
        item-name-prefix="send"
        :use-copy-from-billing-address="true"
      />
      <GeneralRequiredInput
        :item-label="$t('profile.storeName')"
        item-name="send_addr_name"
      />
      <PhoneNumberInput
        :item-label="$t('profile.phoneNumber')"
        item-name="phone_number"
      />
      <EmailInput
        :item-label="$t('profile.email')"
        item-name="email"
      />
      <EmailConfirmInput
        :item-label="$t('profile.email')"
        item-name="email"
      />
      <EmailInput
        :item-label="$t('profile.email') + ' 2'"
        item-name="email2"
      />
      <EmailConfirmInput
        :item-label="$t('profile.email') + ' 2'"
        item-name="email2"
      />
      <PasswordInput v-if="showPassword" />
      <PasswordConfirmInput v-if="showPassword" />
      <PaymentMethodInput
        v-if="showPaymentMethod"
        :is-company="true"
      />
      <BikouInput />
    </table>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import NameInput from '@/components/lib/user/components/inputFormComponents/NameInput.vue'
import AddressInput from '@/components/lib/user/components/inputFormComponents/AddressInput.vue'
import PasswordInput from '@/components/lib/user/components/inputFormComponents/PasswordInput.vue'
import PasswordConfirmInput from '@/components/lib/user/components/inputFormComponents/PasswordConfirmInput.vue'
import PaymentMethodInput from '@/components/lib/user/components/inputFormComponents/PaymentMethodInput.vue'
import BikouInput from '@/components/lib/user/components/inputFormComponents/BikouInput.vue'
import EmailInput from '@/components/lib/user/components/inputFormComponents/EmailInput.vue'
import EmailConfirmInput from '@/components/lib/user/components/inputFormComponents/EmailConfirmInput.vue'
import PhoneNumberInput from '@/components/lib/user/components/inputFormComponents/PhoneNumberInput.vue'
import GeneralRequiredInput from '@/components/lib/user/components/inputFormComponents/GeneralRequiredInput.vue'
import { shouldUseKana } from '@/utils/i18n'

export default defineComponent({
  name: 'InputFormIndividual',
  components: {
    GeneralRequiredInput,
    PhoneNumberInput,
    EmailConfirmInput,
    EmailInput,
    BikouInput,
    PaymentMethodInput,
    PasswordConfirmInput,
    PasswordInput,
    NameInput,
    AddressInput,
  },
  props: {
    showContractType: {
      type: Boolean,
      required: true,
    },
    showPassword: {
      type: Boolean,
      required: true,
    },
    showPaymentMethod: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const vue = getCurrentInstance().proxy
    const isKanaVisible = shouldUseKana()

    function onContractModeChange() {
      vue.$emit('on-contract-mode-change', { isCompany: false })
    }

    return {
      isKanaVisible,
      // methods
      onContractModeChange,
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_title {
  width: 99.7%;
  margin: 30px 0 0 0 !important;
  border: solid 1px #00A9CA !important;
  text-align: center;

  p {
    height: 60px;
    color: #00A9CA;
    font-weight: normal;
    line-height: 60px;
  }
}

.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}

.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

@media screen and (max-width: 640px) {
  .wrap_title {
    width: 99%;
  }
  .wrap_input .slc {
    float: none;
  }
}
</style>
